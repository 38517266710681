/* MODAL STYLES
-------------------------------*/
jw-modal {
  /* modals are hidden by default */
  display: none;

  .jw-modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index must be higher than .jw-modal-background */
    z-index: 1000;
    /* enables scrolling for tall modals */
    overflow: auto;

    .jw-modal-body {
      padding: 20px;
      background: #fff;
      /* margin exposes part of the modal background */
      width: 35% !important;
      margin: 0px auto !important;
      margin-top: 40px !important;
    }
  }

  .jw-modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;
    /* z-index must be below .jw-modal and above everything else  */
    z-index: 900;
  }
}

body.jw-modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}

$prince-red: 900;
$gold: #dfb63b;

.interndetailsfont {
  font-size: 16px !important;
}

.customLink {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

textarea {
  resize: none;
}

td {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 7px;
}

h1 {
  font-size: 43.75px;
  letter-spacing: 0px;
  font-family: "EB Garamond", serif;
  margin: 0px auto 10px;
  line-height: 1.15em;
  font-weight: 500;
}

h2 {
  font-size: 25px;
  letter-spacing: 0px;
  font-family: 'Lato', sans-serif;
  margin: 0px auto 10px;
  line-height: 1.15em;
  font-weight: 500;
}

h4 {
  font-size: 15px;
  letter-spacing: 0px;
  font-family: 'Lato', sans-serif;
  line-height: 1.15em;
  font-weight: 400;
}

p {
  font-family: 'Lato', sans-serif;
  font-size: 16.875px;
  font-weight: 400;
  line-height: 25.3125px;
}

ul {
  list-style: none;
  font-family: 'Lato', sans-serif;
  font-size: 16.875px;
  font-weight: 400;
  line-height: 25.3125px;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin: 0px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #eee;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #808080;
}

.alert-red {
  background-color: #a41f35;
  color: white;
  font-size: 26px;
}

.mat-multi-select-dropdown {
  width: calc(100% + 17px);
}

// had to add these styles here to
// 1. avoid using ::ng-deep
// 2. avoid setting encapsulation to ViewEncapsulation.None in the .ts file
// using the above methods would not just affect the multi-select dropdown, but would allow styles to leak into other components
// having this here would help reuse these styles when needed elsewhere
// reference: https://stackoverflow.com/questions/53684763/how-to-remove-space-bottom-mat-form-field
.mat-multi-select-dropdown .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-multi-select-dropdown .mat-form-field-infix {
  border-top: 0 !important;
  padding-top: 0 !important;
}

.mat-multi-select-dropdown .mat-select-trigger {
  margin-top: 1.5% !important;
}
